import * as Sentry from '@sentry/react';

import axiosInstance from './axiosInterceptor';
import axios from 'axios';
import ApiException from '@exceptions/ApiExceptions';

type Method = 'get' | 'post' | 'put' | 'delete';
type Headers = { [key: string]: string };

type SearchObj = {
  [key: string]: string | number | boolean;
};

const localAxios = axios; // eslint-disable-line
const localAxiosInstance = axiosInstance; // eslint-disable-line

export class BaseAPI {
  headers: Headers;
  endpointUrl: string;
  token?: string;
  noInterception?: boolean;

  constructor(token?: string) {
    this.token = token;
    this.endpointUrl = '';
    this.headers = { 'content-type': 'application/json' };
    this.noInterception = false;
  }

  _setRequestHeaders = () => {
    const _headers = this.headers;
    if (this.token) {
      _headers['Authorization'] = `Bearer ${this.token}`;
    }
    return _headers;
  };

  async _apiCall(method: Method, query = '', data?: unknown) {
    const url = this.endpointUrl + query;
    try {
      if (method === 'get') {
        const resp = await eval(
          `${this.noInterception ? 'localAxios' : 'localAxiosInstance'}`,
        ).get(url, {
          headers: this._setRequestHeaders(),
        });
        return resp.data;
      }
      if (method === 'post') {
        const resp = await eval(
          `${this.noInterception ? 'localAxios' : 'localAxiosInstance'}`,
        ).post(url, data, {
          headers: this._setRequestHeaders(),
        });
        return resp.data;
      }
      if (method === 'put') {
        const resp = await eval(
          `${this.noInterception ? 'localAxios' : 'localAxiosInstance'}`,
        ).put(url, data, {
          headers: this._setRequestHeaders(),
        });
        return resp.data;
      }
      if (method === 'delete') {
        const resp = await eval(
          `${this.noInterception ? 'localAxios' : 'localAxiosInstance'}`,
        ).delete(url, data, {
          headers: this._setRequestHeaders(),
        });
        return resp.data;
      }
    } catch (error: any) {
      const statusCode = error.response?.status || undefined;
      const errorMessage =
        error.response?.data?.message || 'An unexpected error occurred';
      Sentry.captureException(error);
      throw new ApiException(statusCode, errorMessage);
    }
  }

  async get(arg?: number | string) {
    if (arg) {
      if (!Number.isNaN(Number(arg))) {
        arg = `/${arg}`;
      }
      return await this._apiCall('get', arg as string);
    }
    return await this._apiCall('get');
  }

  async search(searchObj: SearchObj) {
    let requestQuery = '?';
    Object.keys(searchObj).forEach((key: string, i: number) => {
      requestQuery += `${key}=${searchObj[key]}${
        i < Object.keys(searchObj).length - 1 ? '&' : ''
      }`;
    });
    return await this.get(requestQuery);
  }

  async create(data: unknown, requestQuery?: string) {
    return await this._apiCall('post', requestQuery, data);
  }

  async update(data: unknown, arg?: number | string, passIdAsParam = false) {
    if (arg && !Number.isNaN(Number(arg))) {
      if (passIdAsParam) {
        arg = `?id=${arg}`;
      } else {
        arg = `/${arg}`;
      }
    }
    return await this._apiCall('put', arg as string | undefined, data);
  }

  async delete(id: number | string, passIdAsParam = false) {
    const requestQuery = passIdAsParam ? `?id=${id}` : `/${id}`;
    return await this._apiCall('delete', requestQuery);
  }
}
